/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ keywords, description, lang, meta, title, image }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
          }
        }
      }
    `
  )

  const $description = description || site.siteMetadata.description
  const $keywords = keywords ? `${site.siteMetadata.keywords}, ${keywords}` : site.siteMetadata.keywords

  return (
    <Helmet
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      htmlAttributes={{
        lang,
      }}
      meta={[
        // General
        { name: `description`, content: $description },
        { name: `keywords`, content: $keywords },

        // Open Graph
        { property: `og:title`, content: title },
        { property: `og:description`, content: $description },
        { property: `og:image`, content: image },
        { property: `og:type`, content: `website` },

        // Twitter
        { name: `twitter:card`, content: `summary` },
        { name: `twitter:creator`, content: site.siteMetadata.author },
        { name: `twitter:title`, content: title },
        { name: `twitter:image`, content: image },
        { name: `twitter:description`, content: $description },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: "nl",
  meta: [],
  description: "",
  image: "",
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
