import React, { useState } from "react"
import PropTypes from "prop-types"
import Link from "../link"

const Links = [
  { label: "Home", to: "/" },
  { label: "Accomodatie", to: "/about" },
  // { label: "Gastronomie", to: "/food-beverages" },
  // { label: "In de buurt", to: "/things-to-do" },
  { label: "In de buurt", to: "/nearby" },
  { label: "Recensies", to: "/reviews" },
  { label: "Contact", to: "/contact" },
]

const NavigationItems = ({ links }) =>
  links.map(({ label, to }) => {
    const isCurrent = false
    const rootClassName = `
    px-3 py-2 rounded-md font-medium
    ${
      isCurrent
        ? "bg-primary-900 text-white"
        : "text-white hover:bg-primary-800 hover:text-white"
    }
  `

    return (
      <Link key={label} to={to} className={rootClassName}>
        {label}
      </Link>
    )
  })

const NavigationItemsMobile = ({ links }) =>
  links.map(({ label, to }) => {
    const isCurrent = false
    const rootClassName = `
    block mx-2 p-2 rounded-md text-base font-medium
    ${
      isCurrent
        ? "bg-primary-900 text-white"
        : "text-white hover:bg-primary-800 hover:text-white"
    }
  `

    return (
      <Link key={label} to={to} className={rootClassName}>
        {label}
      </Link>
    )
  })

const Header = ({ title, transparent }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const toggleMenuOpen = () => setMenuOpen(!menuOpen)
  const rootClassName = `${
    transparent ? "bg-black bg-opacity-50 lg:bg-transparent" : "bg-primary-700"
  }`

  return (
    <nav className={rootClassName}>
      <div className="container p-6 text-white">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <div className="flex mr-2 -ml-2 lg:hidden">
              <button
                type="button"
                className="inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
                onClick={toggleMenuOpen}
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className={`h-6 w-6 ${menuOpen ? "hidden" : "block"}`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
                <svg
                  className={`h-6 w-6 ${menuOpen ? "block" : "hidden"}`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="flex-shrink-0">
              <Link
                to="/"
                className="m-0 text-2xl font-bold no-underline uppercase"
              >
                {title}
              </Link>
            </div>
            <div className="hidden lg:block">
              <div className="flex items-baseline ml-10 space-x-4">
                <NavigationItems links={Links} />
              </div>
            </div>
          </div>
          <div className="block">
            <div className="flex items-center ml-4 md:ml-6">
              <Link className="button-transparent" to="/rent">
                Huren
              </Link>
            </div>
          </div>
        </div>
      </div>

      {menuOpen && (
        <div className="container lg:hidden" id="mobile-menu">
          <div className="px-12 pt-2 pb-3 space-y-1 lg:px-3">
            <NavigationItemsMobile links={Links} />
          </div>
        </div>
      )}
    </nav>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  transparent: PropTypes.bool,
}

Header.defaultProps = {
  title: ``,
  transparent: false,
}

export default Header
