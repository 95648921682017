import React from "react"

const Footer = () => (
  <footer className="bg-gray-100">
    <div className="container px-4 py-2 text-center">
      <small>Copyright © {new Date().getFullYear()}</small>
    </div>
  </footer>
)

export default Footer
